import Image from "@whitelabel-engine/ui/Image";
import Text from "@whitelabel-engine/ui/Text";
import { type Colors } from "@whitelabel-engine/ui/utils/colors";
import { type FC } from "react";

export type CountryBanPlaceholderProps = {
  message?: string;
  logo?: string;
  alt?: string;
  color?: Colors;
  backgroundColor?: Colors;
  backgroundImage?: string;
  logoOpacity?:
    | "10"
    | "20"
    | "25"
    | "30"
    | "40"
    | "50"
    | "60"
    | "70"
    | "80"
    | "90";
};

const CountryBanPlaceholder: FC<CountryBanPlaceholderProps> = ({
  message,
  color = "light",
  backgroundColor = "background",
  logo,
  logoOpacity = "50",
  alt,
  backgroundImage,
}) => {
  return (
    <div
      className={[
        "inset-0",
        "fixed",
        "flex",
        "flex-col",
        "items-end",
        "z-[1000]",
        "p-8",
        "md:p-12",
        `text-${color}`,
        `bg-${backgroundColor}`,
      ].join(" ")}
      style={{
        backgroundImage: `url(${backgroundImage})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
      }}
    >
      <div className="flex h-[calc(100vh-120px)] w-full items-center justify-center md:h-[calc(100vh-120px)]">
        <Text
          variant="h1"
          size="2xl"
          className="max-w-[950px] text-center md:text-4xl"
        >
          {message}
        </Text>
      </div>

      <Image
        className={[
          "h-[80px]",
          "w-[250px]",
          "md:h-[120px]",
          "md:w-[550px]",
          `opacity-${logoOpacity}`,
        ].join(" ")}
        src={logo ?? ""}
        alt={alt ?? ""}
        width={550}
        height={120}
      />
    </div>
  );
};

export default CountryBanPlaceholder;
