"use client";
import * as Sentry from "@sentry/nextjs";
import CountryBanPlaceholder from "@whitelabel-engine/features/CountryBanPlaceholder";
import { useEffect } from "react";

export default function GlobalError({
  error,
}: {
  error: Error & { digest?: string };
}) {
  useEffect(() => {
    Sentry.captureException(error);
  }, [error]);

  return (
    <CountryBanPlaceholder
      message="Hang tight - we're improving your experience and will be back online shortly!"
      logo="/assets/ForjLogo.svg"
      logoOpacity="20"
      alt="Project logo"
    />
  );
}
